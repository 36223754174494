import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as MaterialAvatar } from '@material-ui/core';
import { imagePath } from '../../utils/rest';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    width: props => theme.spacing(props.size),
    height: props => theme.spacing(props.size),
    borderRadius: theme.shape.borderRadius,
    '& img': {
      objectFit: 'contain'
    }
  }
}));

const Thumbnail = ({ className, image, size = 10 }) => {
  const classes = useStyles({ size });

  return (
    <MaterialAvatar className={clsx(className, classes.thumbnail)} variant="square" src={imagePath(image?.source?.small)}>N/A</MaterialAvatar>
  );
};

Thumbnail.propTypes = {
  image: PropTypes.object
};

export default Thumbnail;
