import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';

const Breadcrumbs = ({ className, title, section, children }) => {
  return (
    <div className={clsx(className)}>
      <Grid container alignItems="flex-end" justifyContent="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            {section}
          </Typography>
          <Typography component="h1" variant="h3">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

Breadcrumbs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  section: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default Breadcrumbs;
