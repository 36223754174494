export const STATUS_OK = 200;

export const STATUS_CREATED = 201;

export const STATUS_BAD_REQUEST = 400;

export const STATUS_UNAUTHORIZED = 401;

export const STATUS_FORBIDDEN = 403;

export const STATUS_NOT_FOUND = 404;

export const STATUS_VALIDATION_ERROR = 422;

