import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';

const Formm = ({ formRef, initial = {}, values = {}, schema, onSubmit, validate, children }) => (
  <Formik innerRef={formRef} initialValues={{ ...initial, ...values }} validationSchema={schema} onSubmit={onSubmit} validate={validate}>
    {({ handleSubmit, ...form }) => (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {children(form)}
        </Grid>
      </form>
    )}
  </Formik>
);

Formm.propTypes = {
  children: PropTypes.func.isRequired,
  initial: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
  values: PropTypes.object
};

export default Formm;
