import { all } from 'redux-saga/effects';
import authSagas from './authSagas';
import albumSagas from './albumSagas';

export default function* rootSaga() {
  yield all([
    authSagas.watchInit(),
    authSagas.watchLogin(),
    authSagas.watchLogout(),
    authSagas.watchTokenRefresh(),
    authSagas.watchTokenRefreshSuccess(),
    authSagas.watchTokenRefreshFailure(),

    albumSagas.watchCatalogSearchChanged(),
    albumSagas.watchCatalogNextPage(),
    albumSagas.watchCatalogListRequest(),
    albumSagas.watchCatalogListRequestFailure(),

    albumSagas.watchActiveAlbumChanged(),
    albumSagas.watchActivePageChanged(),
    albumSagas.watchPageCoinListRequest(),

    albumSagas.watchPageListRequest(),
    albumSagas.watchPageListRequestFailure(),
    albumSagas.watchRemovePageRequest(),
    albumSagas.watchRemovePageFailure(),

    albumSagas.watchCreatePageRequest(),
    albumSagas.watchCreatePageFailure(),
    albumSagas.watchUpdatePageRequest(),
    albumSagas.watchUpdatePageFailure(),

    albumSagas.watchAlbumCoinMoved(),
    albumSagas.watchSaveAlbumList(),

    albumSagas.watchSortAlbumPages(),
    albumSagas.watchCopyPage(),
    albumSagas.watchSwapCoinImages(),
    albumSagas.watchSetCoinStatus(),
    albumSagas.watchRefresh()
  ]);
}
