import { call, put, select, takeEvery } from 'redux-saga/effects';
import ALBUM from '../../constants/albumConstants';
import albumActions from '../actions/albumActions';
import coinService from '../../services/coinService';
import {
  selectActiveAlbum,
  selectActivePage, selectAlbumList,
  selectCatalog,
  selectCatalogList,
  selectCatalogSearchParams
} from '../selectors/albumSelectors';
import alertActions from '../actions/alertActions';
import albumService from '../../services/albumService';
import { move, moveBetween } from '../../utils/move';

/********* HELPERS *********/

/********* WATCHERS *********/

function* watchCatalogSearchChanged() {
  yield takeEvery(ALBUM.CATALOG_SEARCH_CHANGED, catalogSearchChanged);
}

function* watchCatalogNextPage() {
  yield takeEvery(ALBUM.CATALOG_NEXT_PAGE, catalogNextPage);
}

function* watchCatalogListRequest() {
  yield takeEvery(ALBUM.CATALOG_LIST_REQUEST, catalogListRequest);
}

function* watchCatalogListRequestFailure() {
  yield takeEvery(ALBUM.CATALOG_LIST_REQUEST_FAILURE, requestFailure);
}

function* watchActiveAlbumChanged() {
  yield takeEvery(ALBUM.ACTIVE_ALBUM_CHANGED, activeAlbumChanged);
}

function* watchActivePageChanged() {
  yield takeEvery(ALBUM.ACTIVE_PAGE_CHANGED, activePageChanged);
}

function* watchPageCoinListRequest() {
  yield takeEvery(ALBUM.PAGE_COIN_LIST_REQUEST, pageCoinListRequest);
}

function* watchPageListRequest() {
  yield takeEvery(ALBUM.PAGE_LIST_REQUEST, pageListRequest);
}

function* watchPageListRequestFailure() {
  yield takeEvery(ALBUM.PAGE_LIST_REQUEST_FAILURE, requestFailure);
}

function* watchRemovePageRequest() {
  yield takeEvery(ALBUM.REMOVE_PAGE_REQUEST, removePageRequest);
}

function* watchRemovePageFailure() {
  yield takeEvery(ALBUM.REMOVE_PAGE_FAILURE, requestFailure);
}

function* watchCreatePageRequest() {
  yield takeEvery(ALBUM.CREATE_PAGE_REQUEST, createPageRequest);
}

function* watchCreatePageFailure() {
  yield takeEvery(ALBUM.CREATE_PAGE_FAILURE, requestFailure);
}

function* watchUpdatePageRequest() {
  yield takeEvery(ALBUM.UPDATE_PAGE_REQUEST, updatePageRequest);
}

function* watchUpdatePageFailure() {
  yield takeEvery(ALBUM.UPDATE_PAGE_FAILURE, requestFailure);
}

function* watchAlbumCoinMoved() {
  yield takeEvery(ALBUM.COIN_MOVED, albumCoinMoved);
}

function* watchSaveAlbumList() {
  yield takeEvery(ALBUM.SAVE_ALBUM_LIST, saveAlbumList);
}

function* watchSortAlbumPages() {
  yield takeEvery(ALBUM.SORT_ALBUM_PAGES, sortAlbumPages);
}

function* watchCopyPage() {
  yield takeEvery(ALBUM.COPY_PAGE, copyPage);
}

function* watchSwapCoinImages() {
  yield takeEvery(ALBUM.SWAP_COIN_IMAGES, swapCoinImages);
}

function* watchSetCoinStatus() {
  yield takeEvery(ALBUM.SET_COIN_STATUS, setCoinStatus);
}

function* watchRefresh() {
  yield takeEvery(ALBUM.REFRESH, refresh);
}

/********* WORKERS *********/

function* catalogSearchChanged() {
  yield put(albumActions.catalogListRequest());
}

function* catalogNextPage() {
  yield put(albumActions.catalogListRequest());
}

function* catalogListRequest() {
  try {
    const { page, perPage } = yield select(selectCatalog);
    const params = yield select(selectCatalogSearchParams);
    const response = yield call(coinService.listCoins, { page: page + 1, perPage, ...params });
    yield put(albumActions.catalogListRequestSuccess(response));
  } catch (error) {
    yield put(albumActions.catalogListRequestFailure(error));
  }
}

function* requestFailure({ error }) {
  yield put(alertActions.error(error.message ? error.message : 'Cannot perform request.'));
}

function* activeAlbumChanged() {
  yield put(albumActions.pageListRequest());
}

function* activePageChanged({ id }) {
  yield put(albumActions.pageCoinListRequest(id));
}

function* pageCoinListRequest({ id }) {
  try {
    const page = yield call(albumService.getAlbumPage, id);
    yield put(albumActions.setAlbumList(page.coins));
  } catch (error) {
    yield put(requestFailure, error);
  }
}

function* pageListRequest() {
  try {
    const album = yield select(selectActiveAlbum);
    const response = yield call(albumService.listAlbumPages, { album, perPage: 1000 });
    yield put(albumActions.pageListRequestSuccess(response));
  } catch (error) {
    yield put(albumActions.pageListRequestFailure(error));
  }
}

function* removePageRequest({ id }) {
  try {
    const response = yield call(albumService.removeAlbumPage, id);
    yield put(albumActions.removePageSuccess(response));
    yield put(albumActions.pageListRequest());
    yield put(alertActions.success('Page has been successfully removed.'));
  } catch (error) {
    yield put(albumActions.removePageFailure(error));
  }
}

function* createPageRequest({ data, options }) {
  try {
    const response = yield call(albumService.createAlbumPage, data);
    yield put(albumActions.createPageSuccess(response));

    if (typeof options.onSuccess === 'function') {
      yield call(options.onSuccess);
    }

    yield put(albumActions.pageListRequest());
  } catch (error) {
    yield put(albumActions.createPageFailure(error));
  }
}

function* updatePageRequest({ data, options }) {
  try {
    const page = yield select(selectActivePage);
    const response = yield call(albumService.saveAlbumPage, page, data);
    yield put(albumActions.updatePageSuccess(response));

    if (typeof options.onSuccess === 'function') {
      yield call(options.onSuccess);
    }

    yield put(albumActions.pageListRequest());
  } catch (error) {
    yield put(albumActions.updatePageFailure(error));
  }
}

function* albumCoinMoved({ source, destination }) {
  console.log('coin moved', { source, destination });

  if (destination) {

    let catalog = yield select(selectCatalogList);
    let album = yield select(selectAlbumList);

    if (source.droppableId === destination.droppableId) {

      if (source.droppableId === 'album') {
        album = yield call(move, album, source.index, destination.index);
        yield put(albumActions.setAlbumList(album));
        yield put(albumActions.saveAlbumList(album));
      } else if (source.droppableId === 'catalog') {
        catalog = yield call(move, catalog, source.index, destination.index);
        yield put(albumActions.setCatalogList(catalog));
      }

    } else {
      let page = yield select(selectActivePage);

      if (page) {
        if (source.droppableId === 'album') {
          ([album, catalog] = yield call(moveBetween, album, catalog, source.index, destination.index));
        } else if (source.droppableId === 'catalog') {
          ([catalog, album] = yield call(moveBetween, catalog, album, source.index, destination.index));
        }

        yield put(albumActions.setCatalogList(catalog));
        yield put(albumActions.setAlbumList(album));
        yield put(albumActions.saveAlbumList(album));
      }

    }
  }
}

function* saveAlbumList({ list }) {
  try {
    let page = yield select(selectActivePage);
    yield call(albumService.saveAlbumPageCoins, page, { list: list.map(i => i.id) });
    yield put(albumActions.pageListRequest());
  } catch (error) {
    yield put(requestFailure, error);
  }
}

function* sortAlbumPages({ album }) {
  try {
    yield call(albumService.sortAlbumPages, album);
    yield put(albumActions.pageListRequest());
  } catch (error) {
    yield put(requestFailure, error);
  }
}

function* copyPage({ data, options }) {
  try {
    let page = yield select(selectActivePage);
    yield call(albumService.copyAlbumPage, page, data);
    yield put(albumActions.pageListRequest());

    if (typeof options.onSuccess === 'function') {
      yield call(options.onSuccess);
    }
  } catch (error) {
    yield put(requestFailure, error);
  }
}

function* refresh() {
  let page = yield select(selectActivePage);
  yield put(albumActions.pageListRequest());
  yield put(albumActions.pageCoinListRequest(page));
}

function* swapCoinImages({ id }) {
  try {
    let page = yield select(selectActivePage);
    yield call(coinService.swapCoinImages, id);
    yield put(albumActions.pageCoinListRequest(page));
  } catch (error) {
    yield put(requestFailure, error);
  }
}

function* setCoinStatus({ id, status }) {
  try {
    yield call(coinService.setCoinStatus, id, status);
    yield put(albumActions.refresh());
  } catch (error) {
    yield put(requestFailure, error);
  }
}

/*********  EXPORT *********/

const albumSagas = {
  watchCatalogSearchChanged,
  watchCatalogNextPage,
  watchCatalogListRequest,
  watchCatalogListRequestFailure,
  watchActiveAlbumChanged,
  watchActivePageChanged,
  watchPageCoinListRequest,
  watchPageListRequest,
  watchPageListRequestFailure,
  watchRemovePageRequest,
  watchRemovePageFailure,
  watchCreatePageRequest,
  watchCreatePageFailure,
  watchUpdatePageRequest,
  watchUpdatePageFailure,
  watchAlbumCoinMoved,
  watchSaveAlbumList,
  watchSortAlbumPages,
  watchCopyPage,
  watchSwapCoinImages,
  watchSetCoinStatus,
  watchRefresh
};

export default albumSagas;
