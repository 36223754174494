/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { colors } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/DashboardRounded';
import PhotoIcon from '@material-ui/icons/PhotoRounded';
import VideoIcon from '@material-ui/icons/LocalMoviesRounded';
import ArticleIcon from '@material-ui/icons/DescriptionRounded';
import GridIcon from '@material-ui/icons/GridOnRounded';
import SettingsIcon from '@material-ui/icons/SettingsRounded';

import { Label } from 'components';

export default [
  {
    title: 'Pages',
    pages: [
      {
        title: 'Dashboard',
        href: '/overview',
        icon: DashboardIcon,
        label: () => <Label color={colors.green[300]}>Home</Label>
      },
      // {
      //   title: 'Articles',
      //   href: '/articles',
      //   icon: ArticleIcon,
      //   children: [
      //     {
      //       title: 'Articles',
      //       href: '/articles'
      //     },
      //     {
      //       title: 'Categories',
      //       href: '/articles/categories'
      //     }
      //   ]
      // },
      // {
      //   title: 'Photos',
      //   href: '/photos',
      //   icon: PhotoIcon,
      //   children: [
      //     {
      //       title: 'Photos',
      //       href: '/photos'
      //     },
      //     {
      //       title: 'Albums',
      //       href: '/photos/albums'
      //     },
      //     {
      //       title: 'Categories',
      //       href: '/photos/categories'
      //     }
      //   ]
      // },
      // {
      //   title: 'Videos',
      //   href: '/videos',
      //   icon: VideoIcon,
      //   children: [
      //     {
      //       title: 'Videos',
      //       href: '/videos'
      //     },
      //     {
      //       title: 'Categories',
      //       href: '/videos/categories'
      //     }
      //   ]
      // },
      {
        title: 'Albums',
        href: '/albums',
        icon: GridIcon,
      },

      {
        title: 'Settings',
        href: '/settings',
        icon: SettingsIcon,
        children: [
          {
            title: 'Countries',
            href: '/settings/countries'
          },
        ]
      },
    ]
  }
];
