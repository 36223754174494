import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
// import { makeStyles } from '@material-ui/styles';

// const useStyles = makeStyles(() => ({
//   helperText: {
//     textAlign: 'right',
//     marginRight: 0
//   },
// }));

const CheckboxInput = ({ field, label, getFieldProps, touched, errors, multiline = false, rows = 1 }) => {
  const fieldProps = getFieldProps(field);

  // const classes = useStyles();

  return (
    <FormGroup row>
      <FormControlLabel label={label} control={<Checkbox checked={fieldProps.value} {...fieldProps} />} />
    </FormGroup>

    // <TextField fullWidth {...fieldProps}
    //   //FormHelperTextProps={{ classes: { root: classes.helperText } }}
    //   //helperText={`${200 - fieldProps.value.length} characters left`}
    //   helperText={(touched[field] && errors[field]) ? errors[field] : ''}
    //   error={Boolean(touched[field] && errors[field])}
    // />
  );
};

CheckboxInput.propTypes = {
  errors: PropTypes.object,
  field: PropTypes.string,
  getFieldProps: PropTypes.func,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  rest: PropTypes.object,
  rows: PropTypes.string,
  touched: PropTypes.object
};

export default CheckboxInput;
