export const selectUser = (state) => state.auth.user;

export const selectRoles = (state) => state.auth.roles.map(role => role.role);

export const selectUserName = (state) => (state.auth.user) ? state.auth.user.firstName + ' ' + state.auth.user.lastName : null;

export const selectUserEmail = (state) => (state.auth.user) ? state.auth.user.email : null;

export const selectToken = (state) => state.auth.token ? state.auth.token.token : null;

export const selectRefreshing = (state) => state.auth.refreshing;

export const selectAuthenticated = (state) => state.auth.authenticated;

export const selectLoginSubmitting = (state) => state.auth.submitting;
