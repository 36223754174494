import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Dialog as MaterialDialog,
  Typography,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: 960
  },
  header: {
    padding: theme.spacing(2),
    margin: '0 auto',
    paddingTop: theme.spacing(3)
  },
  content: {
    margin: '0 auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actions: {
    // backgroundColor: colors.grey[100],
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginRight: theme.spacing(1),
    color: theme.palette.black,
    backgroundColor: colors.grey[200],
    '&:hover': {
      backgroundColor: colors.grey[300]
    }
  }
}));


const Dialog = ({ open = false, children, onClose, className, ...rest }) => {
  const classes = useStyles();

  return (
    <MaterialDialog maxWidth="lg" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)}>
        <div className={classes.header}>
          <Typography align="center" className={classes.title} gutterBottom variant="h4">
            List of elements
          </Typography>
        </div>

        <div className={classes.content}>
          {children}
        </div>

        <div className={classes.actions}>
          <Button className={classes.button} onClick={onClose} variant="contained">Close</Button>
        </div>
      </div>
    </MaterialDialog>
  );
};

Dialog.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Dialog;
