import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Paper, Input, Select, MenuItem, ListSubheader } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  search: {
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  select: {
    flexGrow: 1,
    minWidth: 150
  }
}));

const SearchSelect = ({ className, options = [], grouped = false, ...rest }) => {
  const classes = useStyles();

  let menuItems = [];

  if (options) {
    if (grouped) {
      options.forEach(group => {
        menuItems.push(<ListSubheader key={'group' + group.value}>{group.label}</ListSubheader>);
        if (group.options) {
          group.options.forEach(option => {
            menuItems.push(<MenuItem className={classes.grouped} key={option.value} value={option.value}>{option.label}</MenuItem>);
          });
        }
      });
    } else {
      menuItems = options.map(
        option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
      );
    }
  }

  return (
    <Paper className={clsx(classes.search, className)} elevation={1}>
      <Select displayEmpty disableUnderline placeholder="Search" {...rest} className={classes.select}>
        <MenuItem value=""><em>None</em></MenuItem>
        {menuItems}
      </Select>
    </Paper>
  );
};

SearchSelect.propTypes = {
  className: PropTypes.string,
  getFieldProps: PropTypes.func,
  grouped: PropTypes.bool,
  options: PropTypes.array
};

export default SearchSelect;
