import React, { Children } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tabs as MaterialTabs, Tab, Divider, colors } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const Tabs = ({ match, history, tabs, children }) => {
  const classes = useStyles();
  const { id, tab } = match.params;

  console.log(match);

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  if (!tab) {
    const defaultTab = tabs[0].value;
    const to = (id === undefined) ? `create/${defaultTab}` : `${id}/${defaultTab}`;

    return <Redirect to={to}/>;
  }

  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to="/errors/404"/>;
  }

  return (
    <>
      <MaterialTabs className={classes.tabs} onChange={handleTabsChange} scrollButtons="auto" value={tab} variant="scrollable">
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value}/>
        ))}
      </MaterialTabs>
      <Divider className={classes.divider}/>
      <div className={classes.content}>
        {Children.map(children, child => React.cloneElement(child, { activeTab: tab }))}
      </div>
    </>
  );
};

Tabs.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired
};

export default Tabs;

{/*<Tabs tabs={tabs} match={match} history={history}>*/}
{/*  <TabContent tab="data">*/}
{/*    <Summary/>*/}
{/*  </TabContent>*/}
{/*  <TabContent tab="logs">*/}
{/*    <Logs/>*/}
{/*  </TabContent>*/}
{/*</Tabs>*/}
//
// {
//   path: '/articles/categories/:id',
//     exact: true,
//   component: lazy(() => import('views/Article/ArticleCategory/ArticleCategoryUpdate'))
// },
// {
//   path: '/articles/categories/:id/:tab',
//     exact: true,
//   component: lazy(() => import('views/Article/ArticleCategory/ArticleCategoryUpdate'))
// },
