import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const SubmitButton = ({ children, saving = false }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button type="submit" variant="contained" color="primary" disabled={saving}>{children}</Button>
        {saving && <CircularProgress size={24} className={classes.progress}/>}
      </div>
    </div>
  );
};

SubmitButton.propTypes = {
  children: PropTypes.node,
  saving: PropTypes.bool
};

export default SubmitButton;
