const AUTH = {
  INIT_START: 'AUTH_INIT_START',
  INIT_DONE: 'AUTH_INIT_DONE',

  LOGOUT: 'AUTH_LOGOUT',

  LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',

  REFRESH_REQUEST: 'AUTH_REFRESH_REQUEST',
  REFRESH_SUCCESS: 'AUTH_REFRESH_SUCCESS',
  REFRESH_FAILURE: 'AUTH_REFRESH_FAILURE',
};

export default AUTH;
