import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, ListSubheader } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  grouped: {
    paddingLeft: theme.spacing(4)
  }
}));

const SelectOptions = ({ options = [], grouped = false }) => {
  const classes = useStyles();

  let menuItems = [];

  if (options) {
    if (grouped) {
      options.forEach(group => {
        menuItems.push(<ListSubheader key={'group' + group.value}>{group.label}</ListSubheader>);
        if (group.options) {
          group.options.forEach(option => {
            menuItems.push(<MenuItem className={classes.grouped} key={option.value} value={option.value}>{option.label}</MenuItem>);
          });
        }
      });
    } else {
      menuItems = options.map(
        option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
      );
    }
  }

  return menuItems;
};

SelectOptions.propTypes = {
  grouped: PropTypes.bool,
  options: PropTypes.array
};

export default SelectOptions;
