import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { sortableElement } from 'react-sortable-hoc';
import { Avatar, colors, Grid } from '@material-ui/core';
import { imagePath } from '../../../utils/rest';
import StatusLabel from '../../StatusLabel';

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    backgroundColor: colors.grey[50],
    border: '1px solid ' + colors.grey[200],
    borderRadius: theme.shape.borderRadius,
    margin: '5px 0',
    width: '100%',
    zIndex: 1400,
    cursor: 'pointer',
  },
  thumbnail: {
    width: theme.spacing(9),
    height: theme.spacing(6),
    borderRadius: theme.shape.borderRadius
  },
  header: {}
}));

const SortableItem = sortableElement(({ element }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item>
        <Avatar className={classes.thumbnail} variant="square" src={imagePath(element?.image?.source?.small)}>N/A</Avatar>
      </Grid>
      <Grid item>
        <strong>#{element.id}</strong>
      </Grid>
      <Grid item>
        <StatusLabel status={element.status}/>
      </Grid>
      <Grid item>
        {element.title}
      </Grid>
    </Grid>
  );
});

SortableItem.propTypes = {
  element: PropTypes.object
};

export default SortableItem;
