import { ALERT_CLOSE, ALERT_ERROR, ALERT_SUCCESS } from '../actions';

const alertReducer = (state = [], action) => {
  switch (action.type) {
    case ALERT_SUCCESS:
      return [...state, {
        ...action,
        type: 'success'
      }];
    case ALERT_ERROR:
      return [...state, {
        ...action,
        type: 'error'
      }];
    case ALERT_CLOSE:
      return state.filter(function(message) {
        return message.id !== action.id;
      });
    default:
      return state;
  }
};

export default alertReducer;