import React, { useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Wrapper from './Wrapper';

const useStyles = makeStyles(theme => ({
  uploader: {
    width: '100%',
    height: '100%',
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
    borderRadius: 4,
    textAlign: 'center',
    opacity: 0.8,
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  }
}));

const DropZone = ({ disabled = false, onFilesAdded = null }) => {
  const styles = useStyles();
  const fileInputRef = React.createRef();
  const [highlighted, setHighlighted] = useState(false);

  const openFileDialog = () => {
    if (disabled) return;
    fileInputRef.current.click();
  };

  const onChange = (event) => {
    if (disabled) return;
    if (onFilesAdded) {
      onFilesAdded(Array.from(event.target.files));
    }
  };

  const onDrop = (event) => {
    event.preventDefault();
    if (disabled) return;

    if (onFilesAdded) {
      onFilesAdded(Array.from(event.dataTransfer.files));
    }

    setHighlighted(false);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    if (disabled) return;
    setHighlighted(true);
  };

  const onDragLeave = () => {
    setHighlighted(false);
  };

  return (
    <Wrapper onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop} onClick={openFileDialog}>
      <Grid container className={styles.uploader} direction="column" justifyContent="center">
        <Grid item>
          <CloudUploadIcon color="primary" fontSize="large"/>
        </Grid>
        <Grid item>
          <Typography variant="button" color="primary">
            Click to Upload
          </Typography>
        </Grid>
      </Grid>
      <input multiple style={{ display: 'none' }} ref={fileInputRef} type="file" onChange={onChange}/>
    </Wrapper>
  );
};


export default DropZone;
