import ALBUM from '../../constants/albumConstants';

const catalogSearchChanged = (params) => ({ type: ALBUM.CATALOG_SEARCH_CHANGED, params });
const catalogNextPage = () => ({ type: ALBUM.CATALOG_NEXT_PAGE });

const catalogListRequest = (params) => ({ type: ALBUM.CATALOG_LIST_REQUEST, params });
const catalogListRequestSuccess = (response) => ({ type: ALBUM.CATALOG_LIST_REQUEST_SUCCESS, response });
const catalogListRequestFailure = (error) => ({ type: ALBUM.CATALOG_LIST_REQUEST_FAILURE, error });

const changeActiveAlbum = (id) => ({ type: ALBUM.ACTIVE_ALBUM_CHANGED, id });
const changeActivePage = (id) => ({ type: ALBUM.ACTIVE_PAGE_CHANGED, id });
const pageCoinListRequest = (id) => ({ type: ALBUM.PAGE_COIN_LIST_REQUEST, id });

const pageListRequest = (params) => ({ type: ALBUM.PAGE_LIST_REQUEST, params });
const pageListRequestSuccess = (response) => ({ type: ALBUM.PAGE_LIST_REQUEST_SUCCESS, response });
const pageListRequestFailure = (error) => ({ type: ALBUM.PAGE_LIST_REQUEST_FAILURE, error });

const removePageRequest = (id) => ({ type: ALBUM.REMOVE_PAGE_REQUEST, id });
const removePageSuccess = (response) => ({ type: ALBUM.REMOVE_PAGE_SUCCESS, response });
const removePageFailure = (error) => ({ type: ALBUM.REMOVE_PAGE_FAILURE, error });

const createPageRequest = (data, options) => ({ type: ALBUM.CREATE_PAGE_REQUEST, data, options });
const createPageSuccess = (response) => ({ type: ALBUM.CREATE_PAGE_SUCCESS, response });
const createPageFailure = (error) => ({ type: ALBUM.CREATE_PAGE_FAILURE, error });

const updatePageRequest = (data, options) => ({ type: ALBUM.UPDATE_PAGE_REQUEST, data, options });
const updatePageSuccess = (response) => ({ type: ALBUM.UPDATE_PAGE_SUCCESS, response });
const updatePageFailure = (error) => ({ type: ALBUM.UPDATE_PAGE_FAILURE, error });

const coinMoved = (source, destination) => ({ type: ALBUM.COIN_MOVED, source, destination });
const setCatalogList = (list) => ({ type: ALBUM.SET_CATALOG_LIST, list });
const setAlbumList = (list) => ({ type: ALBUM.SET_ALBUM_LIST, list });
const saveAlbumList = (list) => ({ type: ALBUM.SAVE_ALBUM_LIST, list });

const sortAlbumPages = (album) => ({ type: ALBUM.SORT_ALBUM_PAGES, album });
const copyPage = (data, options) => ({ type: ALBUM.COPY_PAGE, data, options });
const refresh = () => ({ type: ALBUM.REFRESH });
const swapCoinImages = (id) => ({ type: ALBUM.SWAP_COIN_IMAGES, id });
const setCoinStatus = (id, status) => ({ type: ALBUM.SET_COIN_STATUS, id, status });

const albumActions = {
  catalogSearchChanged,
  catalogNextPage,
  catalogListRequest,
  catalogListRequestSuccess,
  catalogListRequestFailure,
  changeActiveAlbum,
  changeActivePage,
  pageCoinListRequest,
  pageListRequest,
  pageListRequestSuccess,
  pageListRequestFailure,
  removePageRequest,
  removePageSuccess,
  removePageFailure,
  createPageRequest,
  createPageSuccess,
  createPageFailure,
  updatePageRequest,
  updatePageSuccess,
  updatePageFailure,
  coinMoved,
  setCatalogList,
  setAlbumList,
  saveAlbumList,
  sortAlbumPages,
  swapCoinImages,
  setCoinStatus,
  copyPage,
  refresh
};

export default albumActions;