import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const CreateButton = ({ to = '' }) => {
  return (
    <Button color="primary" variant="contained" to={to} component={RouterLink}>
      Create
    </Button>
  );
};

CreateButton.propTypes = {
  to: PropTypes.string.isRequired
};

export default CreateButton;
