import React, { Children, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './components';

const Confirmation = ({ onClose, onApply, children, message }) => {

  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleApply = () => {
    closeDialog();
    if (typeof onApply === 'function') {
      onApply();
    }
  };

  const handleClose = () => {
    closeDialog();
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <>
      {Children.map(children, child => React.cloneElement(child, { onClick: openDialog }))}
      <Dialog
        onApply={handleApply}
        onClose={handleClose}
        message={message}
        open={open}
      />
    </>
  );
};

Confirmation.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  message: PropTypes.string,
  onApply: PropTypes.func,
  onClose: PropTypes.func
};

export default Confirmation;
