import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import authActions from './store/actions/authActions';
import { store } from './store';

store.dispatch(authActions.init());

ReactDOM.render(<App/>, document.getElementById('root'));

serviceWorker.unregister();
