import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Dialog as MaterialDialog,
  Typography,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: 960
  },
  header: {
    // backgroundColor: colors.grey[100],
    padding: theme.spacing(2),
    margin: '0 auto',
    paddingTop: theme.spacing(6)
  },
  content: {
    padding: theme.spacing(3),
    maxWidth: 720,
    margin: '0 auto'
  },
  actions: {
    // backgroundColor: colors.grey[100],
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.black,
    backgroundColor: colors.grey[200],
    '&:hover': {
      backgroundColor: colors.grey[300]
    }
  },
  applyButton: {
    color: theme.palette.white,
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[700]
    }
  }
}));

const Dialog = ({ open, onClose, onApply, className, message = 'Are you sure you want to perform this action?', ...rest }) => {

  const classes = useStyles();

  return (
    <MaterialDialog maxWidth="lg" onClose={onClose} open={open}>
      <div {...rest} className={clsx(classes.root, className)}>
        <div className={classes.header}>
          <Typography align="center" className={classes.title} gutterBottom variant="h3">
            {message}
          </Typography>
        </div>
        {/*<div className={classes.content}>*/}
        {/*  <Typography align="center" className={classes.subtitle} variant="subtitle2">*/}
        {/*    This operation is not recoverable.*/}
        {/*  </Typography>*/}
        {/*</div>*/}
        <div className={classes.actions}>
          <Button className={classes.cancelButton} onClick={onClose} variant="contained">
            Cancel
          </Button>
          <Button className={classes.applyButton} onClick={onApply} variant="contained">
            Confirm
          </Button>
        </div>
      </div>
    </MaterialDialog>
  );
};

Dialog.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Dialog;
