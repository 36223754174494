import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  TableCell as MaterialTableCell,
  TableRow as MaterialTableRow,
  TableHead as MaterialTableHead
} from '@material-ui/core';

const TableHead = ({ children, records, selected, selectAll }) => (
  <MaterialTableHead>
    <MaterialTableRow>
      <MaterialTableCell padding="checkbox" style={{width: '0%'}}>
        <Checkbox
          checked={selected.length === records.length}
          color="primary"
          indeterminate={selected.length > 0 && selected.length < records.length}
          onChange={event => selectAll(event.target.checked)}
        />
      </MaterialTableCell>
      {children}
    </MaterialTableRow>
  </MaterialTableHead>
);

TableHead.propTypes = {
  children: PropTypes.node.isRequired,
  records: PropTypes.array,
  selectAll: PropTypes.func,
  selected: PropTypes.array
};

export default TableHead;
