import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@material-ui/core';
import Label from '../Label';

const settings = {
  PUBLIC: colors.green[400],
  HIDDEN: colors.amber[400],
  DISABLED: colors.red[300]
};

const StatusLabel = ({ status }) => (
  <Label variant="contained" color={status in settings ? settings[status] : colors.grey[500]}>{status}</Label>
);

StatusLabel.propTypes = {
  status: PropTypes.oneOf(['PUBLIC', 'HIDDEN', 'DISABLED'])
};

export default StatusLabel;
