import { call, put, fork, cancelled, takeEvery } from 'redux-saga/effects';
import authService from '../../services/authService';
import authActions from '../actions/authActions';
import AUTH from '../../constants/authConstants';
// import alertActions from '../actions/alert.actions';
import dataStorage from '../../utils/dataStorage';
import { STATUS_VALIDATION_ERROR } from '../../constants/rest';
import { transformErrors } from '../../utils/rest';

/********* HELPERS *********/

const isTokenExpired = ({ expires = null }) => {
  return !(expires && ((new Date(expires)).getTime() > (new Date()).getTime()));
};

/********* WATCHERS *********/

function* watchInit() {
  yield takeEvery(AUTH.INIT_START, init);
}

function* watchLogin() {
  yield takeEvery(AUTH.LOGIN_REQUEST, login);
  // while (true) {
  //   const { username, password } = yield take(AUTH.LOGIN_REQUEST);
  //   yield delay(2000);
  //   yield fork(login, username, password);
  // }
}

function* watchLogout() {
  yield takeEvery(AUTH.LOGOUT, logout);
}

function* watchTokenRefresh() {
  yield takeEvery(AUTH.REFRESH_REQUEST, tokenRefresh);
}

function* watchTokenRefreshFailure() {
  yield takeEvery(AUTH.REFRESH_FAILURE, tokenRefreshFailure);
}

function* watchTokenRefreshSuccess() {
  yield takeEvery([AUTH.LOGIN_SUCCESS, AUTH.REFRESH_SUCCESS], saveToken);
}


/********* WORKERS *********/

export function* init() {
  let user = dataStorage.get('user'),
    token = dataStorage.get('token'),
    authenticated = !!(user && token);

  yield put(authActions.initDone({ user, token, authenticated }));

  if (authenticated && isTokenExpired(token)) {
    yield put(authActions.refreshRequest());
  }
}

function* tokenRefresh() {
  try {
    const token = yield call(authService.refresh);
    yield put(authActions.refreshSuccess(token));
  } catch (error) {
    yield put(authActions.refreshFailure(error));
  }
}

function* tokenRefreshFailure(action) {
  console.log('refresh failure watcher', action.error);
  yield put(authActions.logout());
  // yield put(alertActions.error('Session has expired.'));
}

function* saveToken(action) {
  if (action.token) {
    yield call(dataStorage.set, 'token', action.token);
  }

  if (action.user) {
    yield call(dataStorage.set, 'user', action.user);
  }
}

function* logout() {
  //try {
  // yield fork(authService.logout);
  //} catch (e) {
  //skip
  //} finally {
  yield fork(dataStorage.remove, 'user');
  yield fork(dataStorage.remove, 'token');
  // }
}

function* login({ email, password, onError }) {
  //email, password, onError
  try {
    const response = yield call(authService.login, email, password);
    yield put(authActions.loginSuccess(response));
    return response.token;
  } catch (error) {
    const { code = null, data = {} } = error; //message = null,
    if (code === STATUS_VALIDATION_ERROR) {
      yield fork(onError, transformErrors(data));
    } else {
      yield put(authActions.loginFailure(error));
      // onFailedLogin(alertActions.error(message ? message : 'login_failed'))
    }
  } finally {
    if (yield cancelled()) {
      // ...  cancellation handling code here
    }
  }
}

/*********  EXPORT *********/

const authSagas = {
  watchInit,
  watchLogout,
  watchLogin,
  watchTokenRefresh,
  watchTokenRefreshSuccess,
  watchTokenRefreshFailure
};

export default authSagas;
