import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  TableBody as MaterialTableBody,
  TableCell as MaterialTableCell,
  TableRow as MaterialTableRow
} from '@material-ui/core';

const TableRow = ({ children, records, selected, selectOne }) => (
  <MaterialTableBody>
    {records.map(record => (
      <MaterialTableRow hover key={record.id} selected={selected.indexOf(record.id) !== -1}>
        <MaterialTableCell padding="checkbox">
          <Checkbox
            checked={selected.indexOf(record.id) !== -1}
            color="primary"
            onChange={() => selectOne(record.id)}
            value={selected.indexOf(record.id) !== -1}
          />
        </MaterialTableCell>
        {children(record)}
      </MaterialTableRow>
    ))}
  </MaterialTableBody>
);

TableRow.propTypes = {
  children: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
  selectOne: PropTypes.func,
  selected: PropTypes.array
};

TableRow.defaultProps = {
  records: []
};

export default TableRow;
