import React from 'react';
import PropTypes from 'prop-types';
import ImageUpload from '../ImageUpload';

const ImageInput = ({ values, field, setFieldValue }) => {
  const handleChange = (images) => setFieldValue(field, images.length ? images[0] : null);

  return (
    <ImageUpload limit={1} value={values[field] ? [values[field]] : []} onChange={handleChange}/>
  );
};

ImageInput.propTypes = {
  values: PropTypes.object,
  field: PropTypes.string,
  setFieldValue: PropTypes.func
};

export default ImageInput;
