import React from 'react';
import { makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Wrapper from './Wrapper';

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 4
  },
  clear: {
    position: 'absolute',
    background: theme.palette.error.light,
    color: theme.palette.common.white,
    borderRadius: '50%',
    right: 10,
    top: 10,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.error.main
    }
  }
}));


const Image = ({ source, onRemove, ...rest }) => {
  const styles = useStyles();

  return (
    <Wrapper {...rest}>
      <img alt="file upload" src={source} className={styles.image}/>
      <ClearIcon className={styles.clear} onClick={onRemove}/>
    </Wrapper>
  );
};

export default Image;