import rest from '../utils/rest';

export const IMAGE_UPLOAD = '/images';

const upload = async (image, onProgress = null) => {
  const data = new FormData();
  data.append('image', image);
  return rest.post(IMAGE_UPLOAD, data, true, {}, onProgress);
};

const remove = async (id) => rest.remove(IMAGE_UPLOAD + '/' + id);

const imageService = {
  upload,
  remove
};

export default imageService;
