import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { sortableContainer } from 'react-sortable-hoc';

const useStyles = makeStyles(theme => ({}));

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

SortableContainer.propTypes = {
  children: PropTypes.node
};

export default SortableContainer;
