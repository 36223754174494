import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ListSubheader, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  grouped: {
    paddingLeft: theme.spacing(4)
  }
}));

const SelectInput = ({
  field,
  label,
  options = [],
  getFieldProps,
  touched,
  grouped = false,
  size = 'medium',
  errors,
  onChange
}) => {
  const classes = useStyles();
  const error = Boolean(touched[field] && errors[field]);
  const helperText = (touched.title && errors[field]) ? errors[field] : '';

  let menuItems = [];

  if (options) {
    if (grouped) {
      options.forEach(group => {
        menuItems.push(<ListSubheader key={'group' + group.value}>{group.label}</ListSubheader>);
        if (group.options) {
          group.options.forEach(option => {
            menuItems.push(<MenuItem
              className={classes.grouped} key={option.value}
              value={option.value}
            >{option.label}</MenuItem>);
          });
        }
      });
    } else {
      menuItems = options.map(
        option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
      );
    }
  }

  const fieldProps = useMemo(() => getFieldProps(field), [getFieldProps, field]);

  const handleOnChange = useCallback((event) => {
    fieldProps.onChange(event);
    if(onChange){
      onChange(event.target.value);
    }
  }, [fieldProps, onChange])

  return (
    <TextField
      select fullWidth variant="outlined" size={size} {...fieldProps}
      onChange={handleOnChange} label={label} helperText={helperText} error={error}
    >
      {menuItems}
      {/*<SelectOptions grouped={grouped} options={options}/>*/}
    </TextField>
  );
};
SelectInput.propTypes = {
  errors: PropTypes.object,
  field: PropTypes.string,
  getFieldProps: PropTypes.func,
  onChange: PropTypes.func,
  grouped: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  size: PropTypes.string,
  touched: PropTypes.object
};

export default SelectInput;
