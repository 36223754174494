import ALBUM from '../../constants/albumConstants';

const initial = {
  search: {
    search: '',
    country: '',
    period: ''
  },
  catalog: {
    list: [],
    total: 0,
    page: 0,
    perPage: 10
  },
  album: {
    id: null,
    page: null,
    pages: [],
    list: []
  }
};

const albumReducer = (state = initial, action) => {
  let { type } = action;

  switch (type) {
    case ALBUM.CATALOG_SEARCH_CHANGED:
      return { ...state, search: { ...action.params }, catalog: { ...state.catalog, list: [], page: 0, total: 0 } };
    case ALBUM.CATALOG_NEXT_PAGE:
      return { ...state, catalog: { ...state.catalog, page: state.catalog.page + 1 } };
    case ALBUM.CATALOG_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        catalog: { ...state.catalog, list: [...state.catalog.list, ...action.response.data], total: action.response.total }
      };

    case ALBUM.ACTIVE_ALBUM_CHANGED:
      return { ...state, album: { ...state.album, id: action.id, page: null } };
    case ALBUM.ACTIVE_PAGE_CHANGED:
      return { ...state, album: { ...state.album, page: action.id } };
    case ALBUM.PAGE_LIST_REQUEST_SUCCESS:
      return { ...state, album: { ...state.album, pages: action.response.data } };

    case ALBUM.REMOVE_PAGE_SUCCESS:
      return { ...state, album: { ...state.album, page: null } };

    case ALBUM.SET_ALBUM_LIST:
      return { ...state, album: { ...state.album, list: action.list } };
    case ALBUM.SET_CATALOG_LIST:
      return { ...state, catalog: { ...state.catalog, list: action.list } };

    default:
      return state;
  }
};

export default albumReducer;