import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import useRouter from 'utils/useRouter';
import { selectAuthenticated, selectUser } from '../../store/selectors/authSelectors';

// Example of user roles: ['GUEST', 'USER', 'ADMIN'];

const AuthGuard = props => {
  const { children } = props; //roles

  const user = useSelector(selectUser);
  // const roles = useSelector(selectRoles);
  const authenticated = useSelector(selectAuthenticated);

  const router = useRouter();

  useEffect(() => {
    if (!authenticated || !user) {
      router.history.push('/auth/login');
      return;
    }

    // if (!roles.includes(user.role)) {
    //   router.history.push('/errors/401');
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return <Fragment>{children}</Fragment>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.array.isRequired
};

AuthGuard.defaultProps = {
  roles: []
};

export default AuthGuard;
