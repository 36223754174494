import { useState, useEffect } from 'react';

export function useRestWithDependency(request, dependency = [], ...params) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const makeRequest = async () => {
    try {
      setError(null);
      setLoading(true);
      setResponse(await request(...params));
    } catch (err) {
      console.log('userRest catch', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    makeRequest();
  }, dependency);

  return { response, error, loading };
}

export function useRest(request, ...param) {
  return useRestWithDependency(request, [], ...param);
}

export default useRest;