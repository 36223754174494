export const selectCatalogSearchParams = (state) => state.album.search;

export const selectCatalogList = (state) => state.album.catalog.list;

export const selectCatalog = (state) => state.album.catalog;

export const selectActiveAlbum = (state) => state.album.album.id;

export const selectActivePage = (state) => state.album.album.page;

export const selectAlbumPages = (state) => state.album.album.pages;

export const selectAlbumList = (state) => state.album.album.list;

export const selectActivePageData = (state) => selectActivePage(state) ? selectAlbumPages(state).find(x => x.id === selectActivePage(state)) : {};

