import AUTH from '../../constants/authConstants';

const init = () => ({ type: AUTH.INIT_START });
const initDone = (params) => ({ type: AUTH.INIT_DONE, ...params });

const logout = () => ({ type: AUTH.LOGOUT });

const loginRequest = (email, password, onError) => ({ type: AUTH.LOGIN_REQUEST, email, password, onError });
const loginSuccess = (data) => ({ type: AUTH.LOGIN_SUCCESS, ...data });
const loginFailure = (error) => ({ type: AUTH.LOGIN_FAILURE, error });

const refreshRequest = () => ({ type: AUTH.REFRESH_REQUEST });
const refreshSuccess = (token) => ({ type: AUTH.REFRESH_SUCCESS, token });
const refreshFailure = (error) => ({ type: AUTH.REFRESH_FAILURE, error });

const tempStartRefreshing = () => ({ type: AUTH.TEMP_START_REFRESHING });
const tempFinishRefreshing = () => ({ type: AUTH.TEMP_FINISH_REFRESHING });
const tempExpiredToken = () => ({ type: AUTH.TEMP_EXPIRED_TOKEN });

const authActions = {
  init,
  initDone,

  logout,

  loginRequest,
  loginSuccess,
  loginFailure,

  refreshRequest,
  refreshSuccess,
  refreshFailure,

  tempStartRefreshing,
  tempFinishRefreshing,
  tempExpiredToken
};

export default authActions;
