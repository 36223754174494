import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none'
  }
}));

const TabContent = ({ tab, activeTab, children }) => {
  const classes = useStyles();

  return (
    <div className={clsx((tab !== activeTab) && classes.hidden)}>
      {children}
    </div>
  );
};

TabContent.propTypes = {
  activeTab: PropTypes.string,
  children: PropTypes.node,
  tab: PropTypes.string.isRequired
};

export default TabContent;
