import React, { Children, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import { Dialog, SortableContainer, SortableItem } from './components';
import AsyncSearchSelect from '../Search/components/AsyncSearchSelect';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  select: {
    marginBottom: theme.spacing(2)
  }
}));

const SortableList = ({ service, saveService, optionService, field = 'category', grouped = false, children }) => {
  const classes = useStyles();
  const [id, setId] = useState('');
  const [items, setItems] = useState([]);

  const [open, setOpen] = useState(false);
  const openDialog = () => setOpen(true);
  const closeDialog = () => {
    setId('');
    setItems([]);
    setOpen(false);
  };

  useEffect(() => {
    if (typeof optionService === 'function') {
      if (id) {
        service({ orderBy: 'order', order: 'asc', [field]: id }).then(
          response => setItems(response.data)
        );
      } else {
        setItems([]);
      }
    } else {
      if (open) {
        service({ orderBy: 'order', order: 'asc' }).then(
          response => setItems(response.data)
        );
      }
    }
  }, [id, open]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updated = arrayMove(items, oldIndex, newIndex);
    setItems(updated);
    saveService(updated.map(i => i.id));
  };

  return (
    <>
      {Children.map(children, child => React.cloneElement(child, { onClick: openDialog }))}

      <Dialog onClose={closeDialog} open={open}>

        {typeof optionService === 'function' && (
          <AsyncSearchSelect
            className={classes.select}
            grouped={grouped}
            field="category"
            resource={optionService}
            value={id}
            onChange={event => setId(event.target.value)}
          />
        )}

        <SortableContainer onSortEnd={onSortEnd}>
          {items.map((value, index) => (
            <SortableItem key={`item-${value.id}`} index={index} element={value}/>
          ))}
        </SortableContainer>

      </Dialog>
    </>
  );
};

SortableList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  field: PropTypes.string,
  grouped: PropTypes.bool,
  optionService: PropTypes.func,
  params: PropTypes.object,
  saveService: PropTypes.func,
  service: PropTypes.func
};

export default SortableList;
