import rest from '../utils/rest';

const listPhotos = async (params) => rest.get('/photos', params);
const getPhoto = async (id, data) => rest.get(`/photos/${id}`, data);
const savePhoto = async (id, data) => rest.put(`/photos/${id}`, data);
const createPhoto = async (data) => rest.post('/photos', data);
const removePhoto = async (id) => rest.remove(`/photos/${id}`);
const savePhotosOrder = async (list) => rest.post('/photos/order', { list });

const listAlbums = async (params) => rest.get('/photos/albums', params);
const getAlbum = async (id, data) => rest.get(`/photos/albums/${id}`, data);
const saveAlbum = async (id, data) => rest.put(`/photos/albums/${id}`, data);
const createAlbum = async (data) => rest.post('/photos/albums', data);
const removeAlbum = async (id) => rest.remove(`/photos/albums/${id}`);
const albumOptions = async () => rest.get('/photos/albums/options');
const saveAlbumsOrder = async (list) => rest.post('/photos/albums/order', { list });

const listCategories = async (params) => rest.get('/photos/categories', params);
const getCategory = async (id, data) => rest.get(`/photos/categories/${id}`, data);
const saveCategory = async (id, data) => rest.put(`/photos/categories/${id}`, data);
const createCategory = async (data) => rest.post('/photos/categories', data);
const removeCategory = async (id) => rest.remove(`/photos/categories/${id}`);
const categoryOptions = async () => rest.get('/photos/categories/options');
const saveCategoriesOrder = async (list) => rest.post('/photos/categories/order', { list });

const applyWatermark = async (id, params) => rest.post(`/photos/${id}/watermark`, params);
const revertWatermark = async (id) => rest.remove(`/photos/${id}/watermark`);

const photoService = {
  listPhotos,
  getPhoto,
  savePhoto,
  createPhoto,
  removePhoto,
  savePhotosOrder,
  listAlbums,
  getAlbum,
  saveAlbum,
  createAlbum,
  removeAlbum,
  albumOptions,
  saveAlbumsOrder,
  listCategories,
  getCategory,
  saveCategory,
  createCategory,
  removeCategory,
  categoryOptions,
  saveCategoriesOrder,
  applyWatermark,
  revertWatermark
};

export default photoService;
