import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import useRouter from 'utils/useRouter';
import { selectAuthenticated } from '../../store/selectors/authSelectors';

const GuestGuard = props => {
  const { children } = props;

  const router = useRouter();
  const authenticated = useSelector(selectAuthenticated);

  useEffect(() => {
    if (authenticated) {
      router.history.push('/');
    }
  }, [router, authenticated]);

  return <Fragment>{children}</Fragment>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
