import React from 'react';
import PropTypes from 'prop-types';
import useRest from '../../../utils/useRest';
import SearchSelect from './SearchSelect';

const AsyncSearchSelect = ({ className, field, grouped = false, resource, ...rest }) => {
  const { response } = useRest(resource);

  if (response) {
    return <SearchSelect className={className} field={field} grouped={grouped} options={response} {...rest}/>;
  } else {
    return null;
  }
};

AsyncSearchSelect.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string,
  getFieldProps: PropTypes.func,
  grouped: PropTypes.bool,
  resource: PropTypes.func.isRequired
};

export default AsyncSearchSelect;
