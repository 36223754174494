import AUTH from '../../constants/authConstants';

const initial = {
  user: null,
  token: null,
  refreshing: false,
  submitting: false,
  authenticated: false
};

const authReducer = (state = initial, action) => {
  let { type, ...payload } = action;

  switch (type) {
    case AUTH.INIT_DONE:
      return { ...initial, ...payload };

    case AUTH.LOGOUT:
      return { ...initial };

    case AUTH.LOGIN_REQUEST:
      return { ...initial, submitting: true };
    case AUTH.LOGIN_SUCCESS:
      return { ...initial, ...payload, authenticated: true };
    case AUTH.LOGIN_FAILURE:
      return { ...initial };

    case AUTH.REFRESH_REQUEST:
      return { ...state, refreshing: true };
    case AUTH.REFRESH_SUCCESS:
      return { ...state, ...payload, authenticated: true, refreshing: false };
    case AUTH.REFRESH_FAILURE:
      return { ...state, token: {}, authenticated: false, refreshing: false };

    default:
      return state;
  }
};

export default authReducer;
