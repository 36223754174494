import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import tinymce from 'tinymce';
import 'tinymce/themes/silver';
import 'tinymce/plugins/link';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/image';
import 'tinymce/plugins/code';
import 'tinymce/plugins/table';
import 'tinymce/plugins/autoresize';
import 'tinymce/skins/ui/oxide/skin.min.css';
import { debounce } from '../../utils/debounce';

const contentStyle = '* {font-family: "Roboto", "Helvetica", "Arial", sans-serif;} ' +
  'blockquote {color: gray; border-left: 2px solid gray; padding-left: 10px;} ' +
  'table { border-collapse: collapse; }' +
  'table, th, td { border: 1px solid black; }' +
  'td { padding: 2px 10px; }';

const toolbar = 'bold italic underline superscript | h1 h2 h3 h4 | alignleft aligncenter alignright alignjustify | '
  + 'outdent indent | blockquote bullist numlist | image link anchor table | code';

const TinyMce = ({ value, onChange, height }) => {

  const [editor, setEditor] = useState(null);
  const onChangeDebounce = debounce(onChange, 500);
  const plugins = 'link lists anchor image code table ' + ((height) ? '' : 'autoresize');

  const handleSetup = editor => {
    setEditor(editor);
    editor.on('keyup change', () => {
      onChangeDebounce(editor.getContent());
    });
  };

  useEffect(() => {
    tinymce.init({
      branding: false,
      content_style: contentStyle,
      contextmenu: false,
      elementpath: false,
      height: height,
      menubar: false,
      selector: 'textarea#tinymceEditor',
      skin: 'oxide',
      statusbar: false,
      plugins: plugins,
      toolbar: toolbar,
      setup: handleSetup
    });

    return () => {
      tinymce.remove(editor);
    };
  }, []);

  return (
    <textarea id="tinymceEditor" defaultValue={value}/>
  );
};

TinyMce.propTypes = {
  height: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default TinyMce;
