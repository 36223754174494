import { combineReducers } from 'redux';
import authReducer from './authReducer';
import alertReducer from './alertReducer';
import albumReducer from './albumReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  album: albumReducer,
});

export default rootReducer;
