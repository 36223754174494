import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formm, SelectInput, InputGroup, CheckboxInput } from 'components';
import Icon from '@material-ui/icons/ArrowBack';
import { API_URL } from '../../../utils/rest';

const PositionOption = ({ rotate = '0', children }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Icon style={{ transform: `rotate(${rotate}deg)` }}/>
    <span>{children}</span>
  </div>
);

const watermarkOptions = [
  { value: 'default', label: 'www.stojaniv.com.ua' }
];

const percentageOptions = [
  { value: '10', label: '10%' },
  { value: '20', label: '20%' },
  { value: '30', label: '30%' },
  { value: '40', label: '40%' },
  { value: '50', label: '50%' },
  { value: '60', label: '60%' },
  { value: '70', label: '70%' },
  { value: '80', label: '80%' },
  { value: '90', label: '90%' },
  { value: '100', label: '100%' }
];

const positionOptions = [
  { value: 'bottom-left', label: <PositionOption rotate={-45}>Bottom Left</PositionOption> },
  { value: 'top-left', label: <PositionOption rotate={45}>Top Left</PositionOption> },
  { value: 'top-right', label: <PositionOption rotate={135}>Top Right</PositionOption> },
  { value: 'bottom-right', label: <PositionOption rotate={-135}>Bottom Right</PositionOption> }
];

const schema = Yup.object().shape({
  watermark: Yup.string().required(),
  position: Yup.string().nullable(),
  size: Yup.string().nullable(),
  opacity: Yup.string().nullable(),
  inverted: Yup.boolean().nullable()
});

const initial = {
  watermark: 'default',
  position: 'bottom-left',
  size: '40',
  opacity: '30',
  inverted: false
};

const Preview = ({ id, watermark, size, opacity, inverted, position }) => {
  const src = `${API_URL}/photos/${id}/watermark?watermark=${watermark}&size=${size}&opacity=${opacity}&inverted=${inverted}&position=${position}`;
  return <img alt="watermark" style={{ width: '100%' }} src={src}/>;
};

const Form = ({ id, values = {}, onSubmit, formRef }) => (
  <Formm formRef={formRef} initial={initial} schema={schema} values={values} onSubmit={onSubmit}>
    {form => (<>
      <InputGroup right>
        <SelectInput field="watermark" label="Watermark" options={watermarkOptions} {...form}/>
        <SelectInput field="position" label="Position" options={positionOptions} {...form}/>
        <SelectInput field="size" label="Size" options={percentageOptions} {...form}/>
        <SelectInput field="opacity" label="Opacity" options={percentageOptions} {...form}/>
        <CheckboxInput field="inverted" label="Inverted" {...form} />
        {/*<SubmitButton saving={saving}>Refresh Preview</SubmitButton>*/}
      </InputGroup>
      <InputGroup left>
        <Preview
          id={id}
          watermark={form.values.watermark}
          size={form.values.size}
          opacity={form.values.opacity}
          inverted={form.values.inverted}
          position={form.values.position}
        />
      </InputGroup>
    </>)}
  </Formm>
);

Form.propTypes = {
  bindSubmitForm: PropTypes.func,
  id: PropTypes.string,
  onSubmit: PropTypes.func,
  saving: PropTypes.bool,
  values: PropTypes.object
};

export default Form;
