import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, colors } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  deleteButton: {
    color: theme.palette.white,
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[900]
    }
  },
  deleteIcon: {
    marginRight: theme.spacing(1)
  }
}));

const DeleteButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Button onClick={onClick} className={classes.deleteButton} variant="contained">
      <DeleteIcon className={classes.deleteIcon}/>
      Delete
    </Button>
  );
};

DeleteButton.propTypes = {
  onClick: PropTypes.func
};

export default DeleteButton;
