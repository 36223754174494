import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import TextInput from './TextInput';
import { makeStyles } from '@material-ui/styles';
import { slugify } from 'transliteration';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  },
  button: {
    position: 'absolute',
    top: 10,
    right: 10
  }
}));

const options = {
  replace: {
    'и': 'y',
    'И': 'Y',
    'й': 'i',
    'Й': 'I',
    'є': 'ye',
    'Є': 'Ye',
  }
};

const SlugInput = ({ field, source, values, setFieldValue, ...rest }) => {
  const classes = useStyles();

  const handleClick = () => {
    setFieldValue(field, slugify(values[source], options));
  };

  return (
    <div className={classes.root}>
      <TextInput field={field} {...rest} />
      <Button className={classes.button} onClick={handleClick}>Slug</Button>
    </div>
  );
};

SlugInput.propTypes = {
  field: PropTypes.string,
  setFieldValue: PropTypes.func,
  values: PropTypes.object
};

export default SlugInput;
