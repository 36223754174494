import React from 'react';
import PropTypes from 'prop-types';

const TitleLabel = ({ title, slug }) => (
  <><b>{title}</b><br/><span style={{ color: '#999' }}>[{slug}]</span></>
);

TitleLabel.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default TitleLabel;
