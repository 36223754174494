import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    width: 170,
    height: 170,
    padding: theme.spacing(0.5)
  }
}));

const Wrapper = ({ children, ...rest }) => {
  const styles = useStyles();
  return (
    <Paper className={styles.wrapper} {...rest}>
      {children}
    </Paper>
  );
};

export default Wrapper;
