import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TablePagination,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  }
}));

const TableView = ({ className, children, ...props }) => {
  const { total, page, setPage, perPage, setPerPage } = props;

  const classes = useStyles();

  const handleChangePage = (event, page) => setPage(page);

  const handleChangeRowsPerPage = event => setPerPage(event.target.value);

  return (
    <div className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {`${total} Records found. Page ${page + 1} of ${Math.ceil(total / perPage)}`}
      </Typography>
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                {Children.map(children, child => React.cloneElement(child, props))}
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={total}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </CardActions>
      </Card>
      {/*<TableEditBar selected={selected}/>*/}
    </div>
  );
};

TableView.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  records: PropTypes.array.isRequired,
  selectAll: PropTypes.func.isRequired,
  selectOne: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired
};

TableView.defaultProps = {
  records: []
};

export default TableView;
