import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from './index';
import useRest from '../../utils/useRest';

const AsyncSelectInput = ({ label, grouped = false, resource, params = {}, ...rest }) => {
  const { response } = useRest(resource, params);

  return <SelectInput grouped={grouped} label={label} options={response ? response : []} {...rest}/>;
};

AsyncSelectInput.propTypes = {
  errors: PropTypes.object,
  getFieldProps: PropTypes.func,
  grouped: PropTypes.bool,
  label: PropTypes.string,
  params: PropTypes.object,
  resource: PropTypes.func.isRequired,
  touched: PropTypes.object
};

export default AsyncSelectInput;
