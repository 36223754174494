const ALBUM = {

  CATALOG_SEARCH_CHANGED: 'ALBUM_CATALOG_SEARCH_CHANGED',
  CATALOG_NEXT_PAGE: 'CATALOG_NEXT_PAGE',

  CATALOG_LIST_REQUEST: 'ALBUM_CATALOG_LIST_REQUEST',
  CATALOG_LIST_REQUEST_SUCCESS: 'ALBUM_CATALOG_LIST_REQUEST_SUCCESS',
  CATALOG_LIST_REQUEST_FAILURE: 'ALBUM_CATALOG_LIST_REQUEST_FAILURE',

  ACTIVE_ALBUM_CHANGED: 'ACTIVE_ALBUM_CHANGED',
  ACTIVE_PAGE_CHANGED: 'ACTIVE_PAGE_CHANGED',

  PAGE_COIN_LIST_REQUEST: 'ALBUM_PAGE_COIN_LIST_REQUEST',

  PAGE_LIST_REQUEST: 'ALBUM_PAGE_LIST_REQUEST',
  PAGE_LIST_REQUEST_SUCCESS: 'ALBUM_PAGE_LIST_REQUEST_SUCCESS',
  PAGE_LIST_REQUEST_FAILURE: 'ALBUM_PAGE_LIST_REQUEST_FAILURE',

  CREATE_PAGE_REQUEST: 'ALBUM_CREATE_PAGE_REQUEST',
  CREATE_PAGE_SUCCESS: 'ALBUM_CREATE_PAGE_SUCCESS',
  CREATE_PAGE_FAILURE: 'ALBUM_CREATE_PAGE_FAILURE',

  UPDATE_PAGE_REQUEST: 'ALBUM_UPDATE_PAGE_REQUEST',
  UPDATE_PAGE_SUCCESS: 'ALBUM_UPDATE_PAGE_SUCCESS',
  UPDATE_PAGE_FAILURE: 'ALBUM_UPDATE_PAGE_FAILURE',

  REMOVE_PAGE_REQUEST: 'ALBUM_REMOVE_PAGE_REQUEST',
  REMOVE_PAGE_SUCCESS: 'ALBUM_REMOVE_PAGE_SUCCESS',
  REMOVE_PAGE_FAILURE: 'ALBUM_REMOVE_PAGE_FAILURE',

  COIN_MOVED: 'ALBUM_COIN_MOVED',
  SET_ALBUM_LIST: 'ALBUM_SET_ALBUM_LIST',
  SET_CATALOG_LIST: 'ALBUM_SET_CATALOG_LIST',
  SAVE_ALBUM_LIST: 'ALBUM_SAVE_ALBUM_LIST',

  SWAP_COIN_IMAGES: 'ALBUM_SWAP_COIN_IMAGES',
  SET_COIN_STATUS: 'ALBUM_SET_COIN_STATUS',
  SORT_ALBUM_PAGES: 'ALBUM_SORT_ALBUM_PAGES',
  COPY_PAGE: 'ALBUM_COPY_PAGE',
  REFRESH: 'ALBUM_REFRESH',

};

export default ALBUM;
