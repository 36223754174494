import rest from '../utils/rest';

const AUTH_LOGIN_URL = '/auth/login';
const AUTH_LOGOUT_URL = '/auth/logout';
const AUTH_PROFILE_URL = '/auth/profile';
const AUTH_REFRESH_URL = '/auth/refresh';

const login = async (email, password) => rest.post(AUTH_LOGIN_URL, { email, password }, false);

const logout = async () => rest.post(AUTH_LOGOUT_URL, {});

const refresh = async () => rest.post(AUTH_REFRESH_URL, {});

const profile = async () => rest.get(AUTH_PROFILE_URL);

const authService = {
  login,
  logout,
  profile,
  refresh,
  AUTH_LOGIN_URL,
  AUTH_PROFILE_URL,
  AUTH_REFRESH_URL
};

export default authService;
