import rest from '../utils/rest';

const listCoins = async (params) => rest.get('/coins', params);
const getCoin = async (id, data) => rest.get(`/coins/${id}`, data);
const saveCoin = async (id, data) => rest.put(`/coins/${id}`, data);
const createCoin = async (data) => rest.post('/coins', data);
const removeCoin = async (id) => rest.remove(`/coins/${id}`);
const swapCoinImages = async (id) => rest.put(`/coins/${id}/images/swap`);
const setCoinStatus = async (id, status) => rest.put(`/coins/${id}/status`, { status });

const statusOptions = async () => rest.get('/coins/options/status');
const alignmentOptions = async () => rest.get('/coins/options/alignment');
const edgeOptions = async () => rest.get('/coins/options/edge');
const compositionOptions = async () => rest.get('/coins/options/composition');
const shapeOptions = async () => rest.get('/coins/options/shape');

const coinService = {
  listCoins,
  getCoin,
  saveCoin,
  createCoin,
  removeCoin,
  statusOptions,
  alignmentOptions,
  edgeOptions,
  compositionOptions,
  shapeOptions,
  swapCoinImages,
  setCoinStatus
};

export default coinService;
