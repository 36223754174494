import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Paper } from '@material-ui/core';

const InputGroup = ({ children, left, right }) => {

  let media = { xl: 6, md: 6, xs: 12 };

  if (left) {
    media = { xl: 9, md: 8, xs: 12 };
  } else if (right) {
    media = { xl: 3, md: 4, xs: 12 };
  }

  return (
    <Grid item {...media}>
      <Box component={Paper} p={2}>
        <Grid container spacing={2}>
          {Children.map(children, child => (<Grid item xs={12}>{child}</Grid>))}
        </Grid>
      </Box>
    </Grid>
  );
};

InputGroup.propTypes = {
  children: PropTypes.node,
  left: PropTypes.bool,
  right: PropTypes.bool
};

export default InputGroup;
