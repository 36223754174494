import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { debounce } from '../../utils/debounce';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2)
  }
}));

const Search = ({ onSearch, className, children, initial = {}, values = {} }) => {
  const classes = useStyles();

  const validate = debounce(typeof onSearch === 'function' ? onSearch : () => void (0), 500);

  return (
    <Formik initialValues={{ ...initial, ...values }} onSubmit={onSearch} validate={validate}>
      {({ handleSubmit, getFieldProps }) => (
        <form onSubmit={handleSubmit} className={clsx(classes.root, className)}>
          {children && React.Children.map(children, child => (
            React.cloneElement(child, getFieldProps(child.props.field))
          ))}
        </form>
      )}
    </Formik>
  );
};

Search.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  initial: PropTypes.object.isRequired,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object
};

export default Search;
