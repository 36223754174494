/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import OverviewView from './views/Overview';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/overview"/>
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Auth/Login'))
      },
      {
        path: '/auth/logout',
        exact: true,
        component: lazy(() => import('views/Auth/Logout'))
      },
      {
        component: () => <Redirect to="/errors/404"/>
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/401',
        exact: true,
        component: lazy(() => import('views/Error/Error401'))
      },
      {
        path: '/errors/404',
        exact: true,
        component: lazy(() => import('views/Error/Error404'))
      },
      {
        path: '/errors/500',
        exact: true,
        component: lazy(() => import('views/Error/Error500'))
      },
      {
        component: () => <Redirect to="/errors/404"/>
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/overview',
        exact: true,
        component: OverviewView
      },
      //SETTINGS

      {
        path: '/settings/countries',
        exact: true,
        component: lazy(() => import('views/Settings/Country/CountryIndex'))
      },
      {
        path: '/settings/countries/create',
        exact: true,
        component: lazy(() => import('views/Settings/Country/CountryCreate'))
      },
      {
        path: '/settings/countries/:id',
        exact: true,
        component: lazy(() => import('views/Settings/Country/CountryUpdate'))
      },


      // ALBUM

      {
        path: '/albums',
        exact: true,
        component: lazy(() => import('views/Album/AlbumIndex'))
      },

      {
        path: '/coins/:id',
        exact: true,
        component: lazy(() => import('views/Coin/CoinUpdate'))
      },

      //

      {
        path: '/articles/categories',
        exact: true,
        component: lazy(() => import('views/ArticleCategory/ArticleCategoryIndex'))
      },
      {
        path: '/articles/categories/create',
        exact: true,
        component: lazy(() => import('views/ArticleCategory/ArticleCategoryCreate'))
      },
      {
        path: '/articles/categories/:id',
        exact: true,
        component: lazy(() => import('views/ArticleCategory/ArticleCategoryUpdate'))
      },
      {
        path: '/articles',
        exact: true,
        component: lazy(() => import('views/Article/ArticleIndex'))
      },
      {
        path: '/articles/create',
        exact: true,
        component: lazy(() => import('views/Article/ArticleCreate'))
      },
      {
        path: '/articles/:id',
        exact: true,
        component: lazy(() => import('views/Article/ArticleUpdate'))
      },
      {
        path: '/videos/categories',
        exact: true,
        component: lazy(() => import('views/VideoCategory/VideoCategoryIndex'))
      },
      {
        path: '/videos/categories/create',
        exact: true,
        component: lazy(() => import('views/VideoCategory/VideoCategoryCreate'))
      },
      {
        path: '/videos/categories/:id',
        exact: true,
        component: lazy(() => import('views/VideoCategory/VideoCategoryUpdate'))
      },
      {
        path: '/videos',
        exact: true,
        component: lazy(() => import('views/Video/VideoIndex'))
      },
      {
        path: '/videos/create',
        exact: true,
        component: lazy(() => import('views/Video/VideoCreate'))
      },
      {
        path: '/videos/:id',
        exact: true,
        component: lazy(() => import('views/Video/VideoUpdate'))
      },
      {
        path: '/photos/categories',
        exact: true,
        component: lazy(() => import('views/PhotoCategory/PhotoCategoryIndex'))
      },
      {
        path: '/photos/categories/create',
        exact: true,
        component: lazy(() => import('views/PhotoCategory/PhotoCategoryCreate'))
      },
      {
        path: '/photos/categories/:id',
        exact: true,
        component: lazy(() => import('views/PhotoCategory/PhotoCategoryUpdate'))
      },
      {
        path: '/photos/albums',
        exact: true,
        component: lazy(() => import('views/PhotoAlbum/PhotoAlbumIndex'))
      },
      {
        path: '/photos/albums/create',
        exact: true,
        component: lazy(() => import('views/PhotoAlbum/PhotoAlbumCreate'))
      },
      {
        path: '/photos/albums/:id',
        exact: true,
        component: lazy(() => import('views/PhotoAlbum/PhotoAlbumUpdate'))
      },
      {
        path: '/photos',
        exact: true,
        component: lazy(() => import('views/Photo/PhotoIndex'))
      },
      {
        path: '/photos/create',
        exact: true,
        component: lazy(() => import('views/Photo/PhotoCreate'))
      },
      {
        path: '/photos/:id',
        exact: true,
        component: lazy(() => import('views/Photo/PhotoUpdate'))
      },
      {
        component: () => <Redirect to="/errors/404"/>
      }
    ]
  }
];

export default routes;
