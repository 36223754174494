export const move = (list, from, to) => {
  const result = Array.from(list);
  const [removed] = result.splice(from, 1);
  result.splice(to, 0, removed);
  return result;
};

export const moveBetween = (fromList, toList, fromIndex, toIndex) => {
  const from = Array.from(fromList);
  const to = Array.from(toList);

  const [removed] = from.splice(fromIndex, 1);
  to.splice(toIndex, 0, removed);

  return [from, to];
};

// export default move;
