import rest from '../utils/rest';

const listAlbums = async (params) => rest.get('/albums', params);
const getAlbum = async (id, data) => rest.get(`/albums/${id}`, data);
const saveAlbum = async (id, data) => rest.put(`/albums/${id}`, data);
const createAlbum = async (data) => rest.post('/albums', data);
const removeAlbum = async (id) => rest.remove(`/albums/${id}`);
const albumOptions = async () => rest.get('/albums/options');
const sortAlbumPages = async (id) => rest.put(`/albums/${id}/sort`);

const listAlbumCategories = async (params) => rest.get('/albums/categories', params);
const getAlbumCategory = async (id, data) => rest.get(`/albums/categories/${id}`, data);
const saveAlbumCategory = async (id, data) => rest.put(`/albums/categories/${id}`, data);
const createAlbumCategory = async (data) => rest.post('/albums/categories', data);
const removeAlbumCategory = async (id) => rest.remove(`/albums/categories/${id}`);
const albumOptionsCategory = async () => rest.get('/albums/categories/options');

const listAlbumPages = async (params) => rest.get('/albums/pages', params);
const getAlbumPage = async (id, data) => rest.get(`/albums/pages/${id}`, data);
const saveAlbumPage = async (id, data) => rest.put(`/albums/pages/${id}`, data);
const saveAlbumPageCoins = async (id, list) => rest.put(`/albums/pages/${id}/coins`, list);
const createAlbumPage = async (data) => rest.post('/albums/pages', data);
const removeAlbumPage = async (id) => rest.remove(`/albums/pages/${id}`);
const albumOptionsPage = async () => rest.get('/albums/pages/options');
const copyAlbumPage = async (id, data) => rest.post(`/albums/pages/${id}/copy`, data);

const albumService = {
  listAlbums,
  getAlbum,
  saveAlbum,
  createAlbum,
  removeAlbum,
  albumOptions,
  listAlbumCategories,
  getAlbumCategory,
  saveAlbumCategory,
  createAlbumCategory,
  removeAlbumCategory,
  albumOptionsCategory,
  listAlbumPages,
  getAlbumPage,
  saveAlbumPage,
  saveAlbumPageCoins,
  createAlbumPage,
  removeAlbumPage,
  albumOptionsPage,
  sortAlbumPages,
  copyAlbumPage
};

export default albumService;
