import React from 'react';
import PropTypes from 'prop-types';
import { TableCell as MaterialTableCell } from '@material-ui/core';

const TableCell = ({ children, width }) => {
  const style = width !== undefined ? { width: width + '%' } : {};
  return (
    <MaterialTableCell style={style}>{children}</MaterialTableCell>
  );
};

TableCell.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string
};

export default TableCell;
