import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
// import { makeStyles } from '@material-ui/styles';

// const useStyles = makeStyles(() => ({
//   helperText: {
//     textAlign: 'right',
//     marginRight: 0
//   },
// }));

const TextInput = ({ field, label, type, getFieldProps, touched, errors, multiline = false, size = 'medium', rows = 1, helperText = '' }) => {
  const fieldProps = getFieldProps(field);

  // const classes = useStyles();

  return (
    <TextField
      fullWidth {...fieldProps}
      label={label}
      type={type}
      size={size} 
      multiline={multiline} rows={rows}
      variant="outlined"
      //FormHelperTextProps={{ classes: { root: classes.helperText } }}
      //helperText={`${200 - fieldProps.value.length} characters left`}
      helperText={(touched[field] && errors[field]) ? errors[field] : helperText}
      error={Boolean(touched[field] && errors[field])}
    />
  );
};

TextInput.propTypes = {
  errors: PropTypes.object,
  field: PropTypes.string,
  getFieldProps: PropTypes.func,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  rest: PropTypes.object,
  rows: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  helperText: PropTypes.string,
  touched: PropTypes.object
};

export default TextInput;
