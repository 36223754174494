/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import useRouter from 'utils/useRouter';
import { makeStyles } from '@material-ui/styles';

const NODE_ENV = process.env.NODE_ENV;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const Page = props => {
  const { title, children, className, ...rest } = props;
  const classes = useStyles();

  const router = useRouter();

  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return;
    }

    if (window.gtag) {
      window.gtag('config', GA_MEASUREMENT_ID, {
        page_path: router.location.pathname,
        page_name: title
      });
    }
  }, [title, router]);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default Page;
