/* eslint-disable no-undef */
import axios from 'axios';
import authService from '../services/authService';
// import alertActions from '../../state/actions/alert.actions';
import { store } from '../store';
import authActions from '../store/actions/authActions';
import { STATUS_UNAUTHORIZED, STATUS_VALIDATION_ERROR } from '../constants/rest';
import { selectAuthenticated, selectRefreshing, selectToken } from '../store/selectors/authSelectors';


export const API_URL = process.env.REACT_APP_API_URL;
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const wait = (timeout) => new Promise(resolve => setTimeout(resolve, timeout));

export const safeRequest = promise => new Promise(resolve => {
  if (selectRefreshing(store.getState())) {
    wait(1000).then(() => resolve(safeRequest(promise)));
  } else {
    resolve(promise());
  }
});

export const transformErrors = (errors) => {
  let result = {};
  for (let [key, values] of Object.entries(errors)) {
    result[key] = values.join(', ');
  }
  return result;
};

/**
 * @returns {{Authorization: string}|{}}
 */
const authorizationHeaders = (headers) => {
  const token = selectToken(store.getState());
  let authorizationHeaders = (token) ? { 'Authorization': 'Bearer ' + token } : {};
  return Object.assign({ ...headers }, authorizationHeaders);
};

/**
 * @param url
 * @param method
 * @param params
 * @param data
 * @param authorized
 * @param headers
 * @param onProgress
 * @returns {Promise<any>}
 */
const request = async (url, method = 'get', data = {}, params = {}, authorized = false, headers = {}, onProgress = null) => {
  try {
    if (authorized && !selectAuthenticated(store.getState())) {
      // return Promise.reject('You must be authorized.');
      await Promise.reject('You must be authorized.');
    }

    (authorized && (headers = authorizationHeaders(headers)));

    const config = { url, method, params, data, headers, baseURL: API_URL };

    if (onProgress) {
      config.onUploadProgress = (event) => onProgress(Math.round((event.loaded * 100) / event.total));
    }

    const response = await axios(config);

    if (response.statusText === 'OK' || response.statusText === 'Created') {
      return response.data; //TODO: validate json
    }
  } catch (error) {
    if (error.response) {  // The request was made and the server responded with a status code that falls out
      if (error.response.status === STATUS_UNAUTHORIZED) {
        if (url === authService.AUTH_REFRESH_URL) {
          //TODO: redirect to login
          throw new Error({ message: 'Cannot refresh session token. Token is expired' });
        }

        if (!selectRefreshing(store.getState())) {
          store.dispatch(authActions.refreshRequest());
        }

        return await safeRequest(() => request(url, method, data, params, authorized, headers, onProgress));
      }

      if (error.response.status === STATUS_VALIDATION_ERROR) {
        throw error.response.data;
      }

      const { status = 400, message = 'server error' } = error.response.data;
      // store.dispatch(alertActions.error(message));
    } else if (error.request) { // The request was made but no response was received
      // store.dispatch(alertActions.error('no response was received'));
    } else { // Something happened in setting up the request that triggered an Error
      // store.dispatch(alertActions.error(error.message));
    }

    console.log('request catch', error);

    // return await Promise.reject(error);
    throw new Error(error);
  }
};

const get = async (url, params = {}, authorized = true, headers = {}) => request(url, 'get', {}, params, authorized, headers);

const put = async (url, data, authorized = true, headers = {}) => request(url, 'put', data, {}, authorized, headers);

const post = async (url, data, authorized = true, headers = {}, onProgress = null) => request(url, 'post', data, {}, authorized, headers, onProgress);

const remove = async (url, data, authorized = true, headers = {}) => request(url, 'delete', data, {}, authorized, headers);

const rest = { request, get, post, put, remove };

export const imagePath = (img) => img ? SERVER_URL.replace(/\/$/, '') + img : null;

export default rest;
