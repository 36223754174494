import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, colors } from '@material-ui/core';
import Icon from '@material-ui/icons/Pets';
import Icon2 from '@material-ui/icons/Copyright';

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.white,
    marginRight: theme.spacing(1),
    backgroundColor: colors.grey[500],
    '&:hover': {
      backgroundColor: colors.grey[600]
    }
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

const WatermarkButton = ({ children, onClick }) => {
  const classes = useStyles();

  return (
    <Button onClick={onClick} className={classes.button} variant="contained">
      <Icon className={classes.icon}/>
      {children}
    </Button>
  );
};

WatermarkButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func
};

export default WatermarkButton;
