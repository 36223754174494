import { store } from '../index';

const uuid = () => new Date().valueOf();

export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_CLOSE = 'ALERT_CLOSE';

const autoClose = (alertId) => {
  setTimeout(() => {
    store.dispatch(close(alertId));
  }, 3000);
};

const success = (message) => {
  const id = uuid();
  autoClose(id);
  return { type: ALERT_SUCCESS, id, message };
};

const error = (message) => {
  const id = uuid();
  autoClose(id);
  return { type: ALERT_ERROR, id, message };
};

const close = (id) => {
  return { type: ALERT_CLOSE, id };
};

const alertActions = {
  success,
  error,
  close
};

export default alertActions;