import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import Wrapper from './Wrapper';

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
    objectFit: 'cover'
  },
  progress: {
    top: 10,
    right: 10,
    position: 'absolute',
    padding: 2,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  }
}));

const getImagePreview = (file) => URL.createObjectURL(file);

const Preview = ({ file, progress = 0 }) => {
  const styles = useStyles();

  return (
    <Wrapper>
      <img alt="file upload" src={getImagePreview(file)} className={styles.image}/>
      <CircularProgress className={styles.progress} variant="static" size={25} thickness={5} value={progress}/>
    </Wrapper>
  );
};

export default Preview;