import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Image from './Image';
import Preview from './Preview';
import DropZone from './DropZone';
import imageService from '../../services/imageService';
import { imagePath } from '../../utils/rest';

const createEntity = (attributes) => ({
  file: null,
  image: null,
  error: null,
  progress: 0,
  removed: false,
  uploaded: false,
  ...attributes
});

const ImageUpload = ({ value = [], limit = false, onChange }) => {
  const [images, setImages] = useState(value);
  const [entities, setEntities] = useState(value.map(image => createEntity({ image, uploaded: true })));

  const addImage = (image) => {
    setImages(images => {
      const result = [...images, image];
      onChange(result);
      return result;
    });
  };

  const removeImage = (id) => {
    setImages(images => {
      const index = images.findIndex(x => x.id === id);
      if (index !== -1) images.splice(index, 1);
      const result = [...images];
      onChange(result);
      return result;
    });
  };

  const updateEntity = (id, values) => {
    setEntities((entities) => {
      const result = [...entities];
      result[id] = { ...result[id], ...values };
      return result;
    });
  };

  const addEntities = (files) => {
    const length = entities.length;
    files = (limit) ? files.slice(0, limit - uploadLength()) : files;

    const added = files.map((file) => createEntity({ file }));
    setEntities((entities) => [...entities, ...added]);

    added.forEach((upload, index) => {
      const id = length + index;
      uploadEntity(upload.file,
        (image) => {
          addImage(image);
          updateEntity(id, { image, uploaded: true });
        },
        (error) => updateEntity(id, { error }),
        (progress) => updateEntity(id, { progress })
      );
    });

  };

  const uploadEntity = (file, onSuccess, onError, onProgress = null) => {
    imageService.upload(file, onProgress)
      .then(onSuccess)
      .catch(onError);
  };

  const removeEntity = (id) => {
    imageService.remove(entities[id].image.id).then(() => {
      updateEntity(id, { removed: true });
      removeImage(entities[id].image.id);
    }).catch((error) => {
      console.log(['Cannot remove image #' + id, error]);
      // store.dispatch(alertActions.error('Cannot remove image #' + id));
    });
  };

  const uploadLength = () => entities.filter(e => !e.removed).length;
  const uploadAllowed = () => !limit || limit > uploadLength();

  return (
    <Grid container spacing={1}>
      {entities.map((upload, index) => (!upload.removed && (
        <Grid item key={index}>
          {upload.uploaded ? (
            <Image source={imagePath(upload.image.source.small)} onRemove={() => removeEntity(index)}/>
          ) : (
            <Preview file={upload.file} progress={upload.progress}/>
          )}
        </Grid>
      )))}

      {uploadAllowed() && (
        <Grid item>
          <DropZone onFilesAdded={addEntities}/>
        </Grid>
      )}
    </Grid>
  );
};


export default ImageUpload;