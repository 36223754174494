import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const EditButton = ({ to }) => (
  <Button color="primary" component={RouterLink} to={to}>Edit</Button>
);

EditButton.propTypes = {
  to: PropTypes.string
};

export default EditButton;
