import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Dialog as MaterialDialog,
  colors
} from '@material-ui/core';
import Form from './Form';

const useStyles = makeStyles(theme => ({
  root: {
    width: 960
  },
  content: {
    padding: theme.spacing(3),
    // maxWidth: 720,
    margin: '0 auto'
  },
  actions: {
    // backgroundColor: colors.grey[100],
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.black,
    backgroundColor: colors.grey[200],
    '&:hover': {
      backgroundColor: colors.grey[300]
    }
  },
  restoreButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.white,
    backgroundColor: colors.amber[600],
    '&:hover': {
      backgroundColor: colors.amber[700]
    }
  },
  applyButton: {
    color: theme.palette.white,
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[700]
    }
  }
}));

const Dialog = ({ id, formRef, open, onClose, onApply, onRestore, className, ...rest }) => {

  const classes = useStyles();

  const handleSubmit = (values) => console.log(values);

  return (
    <MaterialDialog maxWidth="lg" onClose={onClose} open={open}>
      <div {...rest} className={clsx(classes.root, className)}>
        <div className={classes.content}>
          <Form id={id} onSubmit={handleSubmit} formRef={formRef}/>
        </div>
        <div className={classes.actions}>
          <Button className={classes.cancelButton} onClick={onClose} variant="contained">Cancel</Button>
          <Button className={classes.restoreButton} onClick={onRestore} variant="contained">Restore</Button>
          <Button className={classes.applyButton} onClick={onApply} variant="contained">Apply</Button>
        </div>
      </div>
    </MaterialDialog>
  );
};

Dialog.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  onRestore: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default Dialog;
