import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { TinyMce } from 'components';

const useStyles = makeStyles(theme => ({
  label: {
    ...theme.typography.h6
  },
  error: {
    ...theme.typography.caption,
    color: theme.palette.error.main
  }
}));

const RichTextInput = ({ field, label, touched, errors, height, setFieldValue, getFieldProps }) => {

  const classes = useStyles();
  const { value } = getFieldProps(field);
  const hasError = Boolean(touched[field] && errors[field]);
  const handleChange = value => setFieldValue(field, value);

  return (<>
    {label && (
      <label className={classes.label}>{label}</label>
    )}
    <TinyMce height={height} value={value} onChange={handleChange}/>
    {hasError && (
      <p className={classes.error}>{(touched[field] && errors[field]) ? errors[field] : ''}</p>
    )}
  </>);
};

RichTextInput.propTypes = {
  errors: PropTypes.object,
  field: PropTypes.string,
  getFieldProps: PropTypes.func,
  height: PropTypes.number,
  label: PropTypes.string,
  setFieldValue: PropTypes.func,
  touched: PropTypes.object,
  values: PropTypes.object
};

export default RichTextInput;
