import React from 'react';
import { connect } from 'react-redux';
import { alertActions } from 'store';
import { makeStyles } from '@material-ui/styles';
import { Alert } from 'components';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    top: 70,
    right: 15,
    position: 'fixed',
    zIndex: 9000,
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const AlertContainer = ({ alerts = [], close }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {alerts.map(({ id, message = '', type = 'success' }) => (
        <Alert key={id} className={classes.alert} onClose={() => close(id)} variant={type} message={message}/>
      ))}
    </div>
  );
};

AlertContainer.propTypes = {
  alerts: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return { alerts: state.alert };
};

const mapDispatchToProps = {
  close: alertActions.close
};


export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer);
