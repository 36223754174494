import React, { Children, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './components';
import { alertActions, store } from '../../store';
import photoService from '../../services/photoService';

const Watermark = ({ onClose, onApply, onRestore, children, id }) => {

  const formRef = useRef();
  const [open, setOpen] = useState(false);

  const getFormValues = () => (formRef.current) ? formRef.current.values : {};

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleApply = () => {
    photoService.applyWatermark(id, getFormValues())
      .then(response => {
        store.dispatch(alertActions.success(response.message));
        closeDialog();
        if (typeof onApply === 'function') {
          onApply();
        }
      })
      .catch(error => store.dispatch(alertActions.error(error.message)));
  };

  const handleRestore = () => {
    photoService.revertWatermark(id)
      .then(response => {
        store.dispatch(alertActions.success(response.message));
        closeDialog();
        if (typeof onRestore === 'function') {
          onRestore();
        }
      })
      .catch(error => store.dispatch(alertActions.error(error.message)));
  };

  const handleClose = () => {
    closeDialog();
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <>
      {Children.map(children, child => React.cloneElement(child, { onClick: openDialog }))}
      <Dialog
        id={id}
        formRef={formRef}
        onApply={handleApply}
        onRestore={handleRestore}
        onClose={handleClose}
        open={open}
      />
    </>
  );
};

Watermark.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  onRestore: PropTypes.func
};

export default Watermark;
